import { Box, IconButton, SwipeableDrawer } from "@mui/material";
import { Fragment, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TopMenu from "components/Menu";
import { Link } from "react-router-dom";
import LanguageSelect from "components/LanguageSelect/languageSelect";

export default function TopMenuMobile() {

    const [open, setOpen] = useState(false);
    const toggleDrawer = (value) => {
        setOpen(value)
    }

    return (
        <Fragment>
            <Box
                sx={{
                    width: 30,
                    height: 30,
                    color: '#ffffff',
                    background: 'rgba(19,19,19,.51)',
                    borderRadius: '3px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => toggleDrawer(true)}
            >
                <MenuIcon />
            </Box>
            <SwipeableDrawer
                anchor='right'
                open={open}
                onOpen={() => toggleDrawer(true)}
                onClose={() => toggleDrawer(false)}
            >
                <MenuMobile onClose={() => toggleDrawer(false)} />
            </SwipeableDrawer>
        </Fragment>
    );
}

function MenuMobile({ onClose }: { onClose: () => void }) {

    const handleClose = () => {
        onClose();
    }

    return (
        <Fragment>
            <Box
                sx={{
                    padding: 1.6,
                    paddingLeft: 3.8,
                    minWidth: 330,
                    height: '100vh',
                    background: '#19013b',
                    backgroundPosition: 'bottom -100px right -100px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 3,
                    }}
                >
                    <Link to='/' onClick={handleClose}>
                        <Box
                            sx={{
                                height: 21,
                                '& img': {
                                    height: '100%',
                                }
                            }}
                        >
                            <img src='/logo_singsing.svg' alt='' />
                        </Box>
                    </Link>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        color: '#ffffff',
                    }}
                    onClick={handleClose}
                >
                    <TopMenu />
                </Box>
                <Box>
                    <LanguageSelect/>
                </Box>
            </Box>
        </Fragment>
    );
}