import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Web3Provider } from '@ethersproject/providers'
import React from 'react';

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { BSC_TESTNET_CONFIG } from './connectors';

// <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
export function Spinner({ color, ...rest }: any) {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={color} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  )
}

export function LoadingCircle() {

  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column justify-content-center text-center w-100">
      <div>
        <CircularProgress />
      </div>

      <span>{t('Please wait, transaction is being processed')}</span>
    </div>

  )
}

export function LoadingCircleSimple() {
  return (
    <div className="d-flex flex-column justify-content-center text-center w-100">
      <div>
        <CircularProgress size={20} color='inherit' />
      </div>
    </div>

  )
}


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function AlertSnackbars({ open, handleClose, type, message }: {
  open: boolean, handleClose: () => void,
  type: "error" | "warning" | "info" | "success", message: string
}) {

  return (
    <Box sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export function AlertDialogSuccess(props: any) {
  return (
    <div>
      <Dialog
        open={props.opened}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Transaction Result"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Buy Success, please check your wallet
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function AlertDialogInfo(props: any) {
  return (
    <div>
      <Dialog
        open={props.opened}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Transaction Result"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Transaction Successed
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function AlertDialogNetwork(props: any) {

  const { t } = useTranslation();

  const context = useWeb3React<Web3Provider>()
  const { account } = context

  const handleSwitch = () => {
    props.handleClose();
    window["ethereum"]?.request({
      method: 'wallet_addEthereumChain',
      params: [BSC_TESTNET_CONFIG, account],
    })
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error.message)
      });
  }

  return (
    <div>
      <Dialog
        open={props.opened}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'inherit',
            borderRadius: '20px',
          }
        }}
      >
        <Box
          sx={{
            background: '#19003B',
            borderRadius: '20px',
          }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('Please switch to BSC testnet network')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link to="/">
              <Button onClick={props.handleClose}>
                {t('No')}
              </Button>
            </Link>
            <Button onClick={handleSwitch} autoFocus>
              {t('Yes')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}