import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Box,  Container,  Grid,  useMediaQuery } from '@mui/material';
import { LogoFooter } from './logo';
import "./style.css";


function Footer() {


    const { t } = useTranslation()

    const matches = useMediaQuery('(max-width:767.98px)')

    return (
        <Box>
            {matches ? <div style={{ height: '150px' }}></div>
                : <div style={{ height: '333px' }}></div>
            }
            <Box className='footer'>
                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item md={5} xs={12}>
                            <Box
                                sx={{
                                    pt: 2,
                                }}
                            >
                                <LogoFooter />
                            </Box>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <h5 className="mt-3">{t("Company Infomation")}</h5>
                            <div className="list-style-type-none mt-3 p-0">
                                <span>SING SING PTE. LTD.</span><br/>
                                <span>{t('Address')}: 114 LAVENDER STREET #11-83 </span><br/>
                                <span>CT HUB 2 SINGAPORE (338729)</span>

                            </div>
                            <h5 className="mt-3">{t("Contact")}</h5>
                            <ul className="list-style-type-none mt-3 p-0">
                                <li>Email: contact@singsing.net</li>
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <h5 className="mt-3">{t("Browse")}</h5>
                            <ul className="list-style-type-none mt-3 p-0">
                                <li><Link to="/">{t("Home")}</Link></li>
                                <li><Link to="/constructor">{t("Constructor")}</Link></li>
                                <li><Link to="/breeding">{t("Breeding")}</Link></li>
                                <li><Link to="/my-superpass">{t("My SuperPass")}</Link></li>
                                <li><a href="https://blog.singnft.io/">{t("Team")}</a></li>
                                <li><Link to="#">{t("Company")}</Link></li>
                            </ul>
                        </Grid>
                        <Grid item xs={12}>© SingSing 2021</Grid>
                    </Grid>
                </Container>
                {!matches &&
                    <div style={{ height: '86px' }}></div>
                }

            </Box>

        </Box>
    );

}

export default Footer;
