import { Box } from '@mui/material';
import React, { Fragment } from 'react';
import { NavBar } from '../../NavBar/NavBar';

const MainLayout: React.FC = (): JSX.Element => {
  return (
    <Fragment>
      <Box
        sx={{
          zIndex: 1000,
          width: '100%',
          backgroundColor: '#19003B',
          display: 'flex',
          justifyContent: 'center',
          boxShadow: '0px 3px 6px #00000029',
          position: 'fixed',
        }}
      >
        <NavBar />
      </Box>
      <Box
        sx={{
          height: 70,
        }}
      >
      </Box>
    </Fragment>
  );
};

export default MainLayout;