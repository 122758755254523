export type MenuItem ={
    label: string,
    link: string,
}

export const Config:MenuItem[] = 
    [
        {
            label: 'Breeding',
            link: '/breeding'
        },
        {
            label: 'My SuperPass',
            link: '/my-superpass'
        },

    ]
