// import { useState, useEffect } from 'react';
import { 
    // NavLink as RouterLink, 
    useNavigate
} from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Avatar,
  IconButton
} from '@mui/material'

import { useTranslation } from 'react-i18next'

// import Web3 from 'web3'
import { useWeb3React } from "@web3-react/core"
import { Web3Provider } from '@ethersproject/providers'

import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AccountIcon from './AccountIcon'

import { useSnackbar } from 'notistack'

import { shortAddress } from 'utils/utils';

type SideBarMenuProps = {
    onCloseDrawer: VoidFunction
}


export default function SideBarMenuDesktop({ onCloseDrawer }: SideBarMenuProps) {

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar();

    // const web3 = new Web3(Web3.givenProvider);
    const context = useWeb3React<Web3Provider>()
    const { account, active, deactivate } = context

    const navigate = useNavigate();


    const handleDisconnectAll = () => {
        deactivate();
        onCloseDrawer();
        // @ts-ignore
        // (connector as any).close()
    }

    const handleCopyMyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account)
            enqueueSnackbar(t('copied'), { variant: "success" });
        }
    }

    return (
        <>
            <List sx={{ width: '311px', maxWidth: '100%', position: 'relative'}}>
                <IconButton 
                    sx={{ position: 'absolute', top: 10, right: 10, zIndex: 9999 }} 
                    onClick={onCloseDrawer} >
                    <CloseSharpIcon fontSize='large' />
                </IconButton>
                <ListItemButton>
                    <ListItemIcon>
                        <Avatar>
                            <AccountIcon fontSize='large' />
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText>
                        { active ? shortAddress(account) : "Connect" }
                    </ListItemText>
                </ListItemButton>

                <ListItemButton sx={{ background: '#4D297C', borderRadius: '50px', marginTop: 2 }} 
                    onClick={() => { 
                        onCloseDrawer()
                        navigate("my-superpass") 
                    }} >
                    <ListItemText>
                        { t('My SuperPass') }
                    </ListItemText>
                    <ChevronRightIcon sx={{ color: '#00E0F7'}} />
                </ListItemButton>

                <ListItemText primary="Account" sx={{ marginTop: 2 }} >
                    { t('Account') }
                </ListItemText>

                <ListItem sx={{ background: '#4D297C', borderRadius: '20px'}}>
                    <List sx={{ width: '100%'}}>
                        <ListItemButton onClick={handleCopyMyAddress}>
                            <ListItemText primary={ t("Copy my address") } />
                        </ListItemButton>
                        {/* <ListItemButton divider>
                            <ListItemText primary={ t("Deposit Ethereum") } />
                        </ListItemButton> */}
                    </List>
                </ListItem>

                <ListItem sx={{ background: '#4D297C', borderRadius: '20px', marginTop: 2 }}>
                    <List sx={{ width: '100%'}}>
                        {/* <ListItemButton divider>
                            <ListItemText primary={ t("Connect to another Wallet") }  />
                        </ListItemButton> */}
                        <ListItemButton onClick={handleDisconnectAll}>
                            <ListItemText primary={ t("Disconnect All") }  />
                        </ListItemButton>
                    </List>
                </ListItem>

            </List>
        </>
        
    )

}