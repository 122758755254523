import { useState } from "react";

import {
    Stack,
    Avatar,
    Button,
    Typography,
    Drawer,
    Box,
    Hidden
} from '@mui/material';

// @mui
// import { useTheme } from '@mui/material/styles';

import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers'

import { shortAddress } from 'utils/utils'
// import ConnectDialog from '../../ConnectWallet'
import ConnectWalletDialog from '../../ConnectWalletDialog'

import AccountIcon from './AccountIcon'
import SideBarMenuDesktop from './SideBarMenuDesktop'

import { useTranslation } from 'react-i18next'
import theme from "theme";

export default function WalletInfo() {

    // const theme = useTheme();

    const { t } = useTranslation()

    const context = useWeb3React<Web3Provider>()
    const { account, active } = context

    const [open, setOpen] = useState(false);
    const [sideMenuDrawerOpen, setSideMenuDrawerOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSideMenuDrawerOpen = () => {
        setSideMenuDrawerOpen(true);
    };

    const handleSideMenuDrawerClose = () => {
        setSideMenuDrawerOpen(false);
    };

    const isMobile = theme.breakpoints.down("sm");

    return (
        <>
            {active ? (
                <Button variant="text" sx={{ borderRadius: '10px' }} onClick={handleSideMenuDrawerOpen}>
                    <Stack direction="row" spacing={1}>
                        <Hidden smDown>
                            <Typography>{shortAddress(account)}</Typography>
                        </Hidden>
                        <Avatar
                            sx={{
                                width: 24,
                                height: 24,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 12,
                            }}
                        >
                            <AccountIcon sx={{ width: 12, height: 12 }} />
                        </Avatar>
                    </Stack>
                </Button>
            ) : (
                <Button onClick={handleOpen}
                    sx={{
                        background: '#68F9FC',
                        borderRadius: '10px',
                        mx: 1,
                    }}
                >
                    <Typography px={2} 
                    sx={{ 
                        color: 'black', 
                        fontSize: [12, 12, 14, 16, 16], 
                        fontWeight: 500 
                    }}>
                            {isMobile? t('Connect'):t('Connect wallet')}
                    </Typography>
                </Button>
            )}

            <ConnectWalletDialog
                open={open}
                onClose={handleClose}
            />

            {active && (
                <Drawer
                    anchor="right"
                    open={sideMenuDrawerOpen}
                    onClose={handleSideMenuDrawerClose}
                >
                    <Box px={2} py={2} sx={{
                        background: '#351A59',
                        minHeight: '100vh',
                        overflowY: 'auto'
                    }} >
                        <SideBarMenuDesktop onCloseDrawer={handleSideMenuDrawerClose} />
                    </Box>
                </Drawer>
            )}

        </>
    )

}
