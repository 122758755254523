import './App.css';

import { HelmetProvider } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import { mainRoutes } from './config/router';
import MainLayout from 'components/layout/MainLayout';
import Footer from 'components/Footer/footer';

function App() {



  const routing = useRoutes(mainRoutes);


  return (
    <HelmetProvider>
      <MainLayout />
      {routing}
      <Footer/>
    </HelmetProvider>
  );
}

export default App;