import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Config } from './config'

export default function TopMenu() {
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: [
                    'flex-start',
                    'flex-start',
                    'center',
                    'center',
                    'center'
                ],
                flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                    xl: 'row',
                },
                color: 'inherit',
            }}
        >
            {Config && Config.length > 0 && Config.map((item, index) => (
                <Link to={item.link} key={index}
                    style={{
                        color: 'inherit',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: [14, 14, 12, 12, 16],
                            color: 'inherit',
                            fontWeight: '500',
                            fontFamily: 'Montserrat',
                            marginRight: '3rem',
                            letterSpacing: 0,
                            mb: [2.6, 2.6, 0, 0, 0],
                        }}
                        noWrap
                    >
                        {t(item.label)}
                    </Typography>
                </Link>
            ))}
        </Box>
    );
}