/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { 
    Box, 
    Dialog, 
    Typography,
    Stack,
    Divider,
    Button,
    IconButton,
    CircularProgress
} from "@mui/material";

import { useTranslation } from 'react-i18next'
import { usePageVisibility } from 'react-page-visibility';

import CloseIcon from '@mui/icons-material/Close';

import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from '@web3-react/frame-connector'
import { Web3Provider } from '@ethersproject/providers'

import { useEagerConnect, useInactiveListener } from '../hooks'
import {
    injected,
    walletconnect,
    BSC_TESTNET_CONFIG
} from '../connectors'
import { AlertDialogNetwork } from '../Spinner'
import React, { Fragment } from "react"
import { shortAddress } from "utils/utils"

const metaMaskIcon = '/assets/icons/metamask.png'
const walletConnectIcon = '/assets/icons/walletconnect.png'

require('dotenv').config()

enum ConnectorNames {
    Injected = 'Metamask Injected',
    WalletConnect = 'WalletConnect',
}

const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
}

function getErrorMessage(error: Error, t: any) {

    if (error instanceof NoEthereumProviderError) {
        return t('No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.')
    } else if (error instanceof UnsupportedChainIdError) {
        return t("You're connected to an unsupported network.")
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect ||
        error instanceof UserRejectedRequestErrorFrame
    ) {
        return t('Please authorize this website to access your BSC account.')
    } else {

        if (error["code"] === -32002) {    // Request of type 'wallet_requestPermissions' alread
            return t("Please open MetaMask to continue")
        }

        console.error(error)
        return t('An unknown error occurred. Check the console for more details.')
    }
}

type WalletIconProps = {
    connectorName: ConnectorNames
}

function WalletIcon({ connectorName }: WalletIconProps) {

    return (
        <>
            { connectorName === ConnectorNames.Injected && (
                <img src={metaMaskIcon} width="30px" height="30px" alt={connectorName} />
            )}

            { connectorName === ConnectorNames.WalletConnect && (
                <img src={walletConnectIcon} width="30px" height="30px" alt={connectorName} />
            )}
        </>
    )
}


export type IProps = {
    open: boolean,
    onClose: () => void;
}

export default function ConnectWalletDialog(props: IProps) {
    const { open, onClose } = props;

    const { t } = useTranslation()
    const isVisible = usePageVisibility()

    const context = useWeb3React<Web3Provider>()
    const { connector, chainId, account, activate, deactivate, active, error } = context

    const [activatingConnector, setActivatingConnector] = React.useState<any>()
    const [connectingConnector, setConnectingConnector] = React.useState<any>()

    const [openD, setOpenD] = React.useState(false)
    const handleCloseD = () => {
        setOpenD(false)
    }
    const chainAccept = Number(process.env.NETWORK_ID) | 97

    React.useEffect(() => {
        console.log(`ConnectWalletDialog - React.useEffect active: ${active} account: ${account} error: ${error}`)
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined)
        }
        if (active && chainId) {
            if (chainAccept !== chainId) {
                setOpenD(true)
                deactivate();

                // for case other tab using other network --> metamask alway ask switch to BSC network
                /* if (isVisible) {
                    // suggest add new chain id
                    window["ethereum"]?.request({
                        method: 'wallet_addEthereumChain',
                        params: [BSC_TESTNET_CONFIG, account],
                    })
                    .then((result) => {
                        console.log(result)
                    })
                    .catch((error) => {
                        console.log(error.message)
                    });
                } */
                
            }
            else {
                setOpenD(false)
            }
            
            return;
        }

    }, [active, account, chainId])

    const triedEager = useEagerConnect()

    useInactiveListener(!triedEager || !!activatingConnector)

    const handleClose = () => {
        onClose();
    }

    const getKeyValue = <T extends object, U extends keyof T>(obj: T) => (key: U) => obj[key];

    return (
        <Fragment>
            <AlertDialogNetwork opened={openD} handleClose={handleCloseD} />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: 'transparent',
                        backgroundImage: 'none',
                        width: { xs: '100%', md: 'inherit' }
                    }
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: { md: '485px', xs: '100%'},
                        minHeight: 300,
                        height: '100%',
                        display: 'flex',
                        padding: '1.1',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex_start',
                        background: `#351A59`,
                        borderRadius: '30px',
                        border: 'solid 2px transparent',
                        backgroundClip: 'padding-box',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        '::before': {
                            content: '" "',
                            position: 'absolute',
                            zIndex: -1,
                            borderRadius: '30px',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            margin: '-2px',
                            background: 'linear-gradient(180deg, rgba(242,113,229,1) 0%, rgba(53,26,89,1) 100%)',
                        },
                    }}
                >
                    <Stack sx={{ width: '100%' }} justifyContent="center" alignItems="center" spacing={1}>
                        <Typography
                            sx={{
                                fontSize: { sx: '16px', md: '20px' },
                                paddingTop: 2,
                                paddingBottom: 1
                            }}
                        >
                            { t('Connect your Wallet') }
                        </Typography>
                        
                        <Divider sx={{ width: '100%'}} />

                        <Stack sx={{ 
                                width: '100%',
                                paddingX: { xs: 2, md: 4 }
                            }}
                            spacing={2}
                        >
                            {/* Connector */}
                            { connector && active && (
                                <Box sx={{
                                    border: '1.5px solid #68F9FC',
                                    background: '#5D3396',
                                    borderRadius: '50px',
                                    height: '50px',
                                    marginTop: 2
                                }}>
                                    <Stack direction="row" 
                                        justifyContent="space-between" 
                                        alignItems="center" 
                                        sx={{ height: '100%' }}
                                        paddingLeft={1} 
                                        paddingRight={2}
                                    >
                                         <Stack direction="row" spacing={1} alignItems="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                         
                                            { Object.keys(connectorsByName)
                                                .filter((connectorName: any) => {
                                                    const currentConnector = getKeyValue(connectorsByName)(connectorName)
                                                    if (currentConnector === connector) {
                                                        return true
                                                    }
                                                    return false
                                                }).map((connectorName: any, index) => {
                                                    return (<WalletIcon key={index} connectorName={connectorName} />)
                                                })
                                            }
                                            
                                            <Typography 
                                                sx={{ 
                                                    fontSize: { sx: '14px', md: '18px'}, 
                                                    fontWeight: 700 
                                                }}
                                                noWrap
                                            >
                                                {shortAddress(account)}
                                            </Typography>
                                        </Stack>
                                        <Button variant="text"
                                            onClick={() => {
                                                deactivate();
                                                setConnectingConnector('');
                                                //(connector as any).close();
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: { sx: '12px', md: '14px'}, 
                                                    fontWeight: 600 
                                                }}
                                            >
                                                { t('Disconnect') }                                                
                                            </Typography>
                                        </Button>
                                    </Stack>
                                    
                                </Box>
                            )}

                            <Typography
                                sx={{
                                    fontSize: { sx: '12px', md: '14px' },
                                    fontWeight: 700,
                                }}
                            >
                                { t('All Providers') }
                            </Typography>

                            { Object.keys(connectorsByName)
                                .filter((connectorName: any) => {
                                    const currentConnector = getKeyValue(connectorsByName)(connectorName)
                                    if (currentConnector !== connector || !account) {
                                        return true
                                    }
                                    return false
                                })
                                .map((name: any, index) => {
                                    return (
                                        <Button 
                                            key={index}
                                            sx={{ 
                                                background: '#5D3396', 
                                                borderRadius: '50px',
                                                height: '50px'
                                            }}
                                            fullWidth
                                            onClick={async () => {
                                                console.log(`setActivatingConnector ${name}`)
                                                //deactivate()
                                                /* if (activatingConnector != null && activatingConnector.hasOwnProperty('close')) {
                                                    activatingConnector.close()
                                                } */

                                                try {
                                                    setConnectingConnector(name)
                                                    setActivatingConnector(getKeyValue(connectorsByName)(name))
                                                    await activate(getKeyValue(connectorsByName)(name))
                                                    console.log(`close activate active: ${active} - account: ${account} - error: ${error}`)
                                                }
                                                catch (exception: any) {
                                                    console.log(`exception: ${exception.message}`)
                                                }

                                            }}
                                        >
                                            <Stack direction="row" alignItems="center" justifyContent="space-between" 
                                                sx={{ width: '100%' }} 
                                                paddingLeft={1} 
                                                paddingRight={2}
                                            >
                                                <Stack direction="row" spacing={1} alignItems="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    <WalletIcon connectorName={name} />
                                                    <Typography 
                                                        sx={{ 
                                                            fontSize: { sx: '14px', md: '18px'}, 
                                                            fontWeight: 700 
                                                        }}
                                                        noWrap
                                                    >
                                                        {name}
                                                    </Typography>
                                                </Stack>
                                                
                                                { connectingConnector === name ?  
                                                    <CircularProgress size={24} /> : 
                                                    <Typography
                                                        sx={{
                                                            fontSize: { sx: '12px', md: '14px'}, 
                                                            fontWeight: 600 
                                                        }}
                                                    >
                                                        { t('Connect') }
                                                    </Typography>
                                                }
                                            </Stack>
                                        </Button>
                                    )
                                })
                            }

                        </Stack>

                        {!!error &&
                            <Box paddingX={4} paddingTop={2}>
                                <Typography variant="h6">
                                    {getErrorMessage(error, t)}
                                </Typography>
                            </Box>
                        }

                        <Box sx={{ 
                                width: '100%',
                                padding: { xs: 2, md: 4}
                            }} >
                            <Button sx={{ 
                                    background: '#F271E5',
                                    borderRadius: '50px',
                                    height: { sx: '40px', md: '50px'}
                                }}
                                onClick={handleClose}
                                fullWidth
                            >
                                <Typography sx={{
                                    fontSize: { xs: '14px', md: '18px' },
                                    fontWeight: 500,
                                    color: '#000'
                                }}>
                                    { t('Continue') }
                                </Typography>
                            </Button>
                        </Box>

                    </Stack>

                    {/* Close Button */}
                    <IconButton sx={{ position: 'absolute', top: '10px', right: '10px'}} 
                        onClick={handleClose} >
                        <CloseIcon fontSize="large" sx={{ color: '#F271E5' }} />
                    </IconButton>

                </Box>
            </Dialog>
        </Fragment>
    );
}