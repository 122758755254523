import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const POLLING_INTERVAL = 12000
const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.REACT_APP_RPC_URL_1 as string,
  4: process.env.REACT_APP_RPC_URL_4 as string,
  56: "https://bsc-dataseed1.binance.org",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  1337: "http://localhost:7545",
}

export const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42, 56, 1337, 97] })


export const walletconnect = new WalletConnectConnector({
  rpc: { 56: RPC_URLS[56], 97: RPC_URLS[97]},
  qrcode: true,
  //pollingInterval: POLLING_INTERVAL
})

const toHex = (num) => {
  return '0x'+num.toString(16)
}

export const BSC_TESTNET_CONFIG = {
  chainId: toHex(97), // A 0x-prefixed hexadecimal string
  chainName: "Binance Smart Chain Testnet",
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "tBNB", // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: [
    "https://data-seed-prebsc-1-s1.binance.org:8545",
    "https://data-seed-prebsc-2-s1.binance.org:8545",
    "https://data-seed-prebsc-1-s2.binance.org:8545",
    "https://data-seed-prebsc-2-s2.binance.org:8545",
    "https://data-seed-prebsc-1-s3.binance.org:8545",
    "https://data-seed-prebsc-2-s3.binance.org:8545"
  ],
  blockExplorerUrls: [ "https://testnet.bscscan.com" ]
}