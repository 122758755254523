import { createTheme } from '@mui/material/styles';


// A custom theme for this app
const theme = createTheme({
  spacing: 10,
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#2EC9EB',
    },
    background: {
      default: '#19003B'
    }
  },
  typography: {
    fontFamily: [
      'Saira',
      'Montserrat',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    htmlFontSize: 10,
    "h1":{
      fontSize: '5rem',
      fontWeight: 600,
      color: 'inherit',
    },
    "h2": {
      fontSize: '4rem',
      fontWeight: 600,
      color: 'inherit',
    },
    "body2":{
      fontSize: '1.4rem',
    },
    "body1":{
      fontSize: '1.4rem',
    },
    allVariants:{
      fontSize: '1.6rem',
    },
    button:{
      fontSize: '1.6rem',
    },
  },
  
});

export default theme;