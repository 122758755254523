import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IData } from '..';

export interface SelectState {
    value: number;
    singerId: number | undefined,
    dame: IData | null,
    sire: IData | null,
    isUpdated: boolean;
}

const initialState: SelectState = {
    value: 0,
    singerId: undefined,
    dame: null,
    sire: null,
    isUpdated: false,
};


export const selectSlice = createSlice({
    name: 'select',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeSelected: (state, action: PayloadAction<number>) => {

            state.value = action.payload;
        },
        changeIsUpdated: (state) => {

            state.isUpdated = !state.isUpdated;
        },
        setSingerId: (state, action: PayloadAction<number|undefined>) => {

            state.singerId = action.payload;
        },
        setDame: (state, action: PayloadAction<IData | null>) => {

            state.dame = action.payload;
        },
        setSire: (state, action: PayloadAction<IData | null>) => {

            state.sire = action.payload;
        },
    },
});

export const {
    changeSelected,
    changeIsUpdated,
    setSingerId,
    setDame,
    setSire,
} = selectSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBreeding = (state: RootState) => state.select;

export default selectSlice.reducer;
