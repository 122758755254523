/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Hidden } from '@mui/material';
import LanguageSelect from 'components/LanguageSelect/languageSelect';
import { Link } from 'react-router-dom';
import useWidth from 'utils/useWidth';
import TopMenu from '../Menu/index';
import TopMenuMobile from './components/TopMenuMobile';
import WalletInfo from './components/WalletInfo';
export function NavBar() {

  const width = useWidth();

  return (
    <Container maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: '#ffffff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: 70,
        }}
      >
        <Link to='/'>
          <Box
            sx={{
              height: [30, 30, 40, 50, 50],
              '& img': {
                height: '100%',
              }
            }}
          >
            <img src='/logo_singsing.svg' className="h-100" alt="" />
          </Box>
        </Link>

      </Box>

      <Hidden lgDown>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: 70,
            justifyContent: 'flex-end',
          }}
        >
          <TopMenu />
          <WalletInfo />
          <Box
            sx={{
              paddingLeft: 2,
            }}
          >
            <LanguageSelect />
          </Box>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <WalletInfo />
          <TopMenuMobile />
        </Box>
      </Hidden>

    </Container>
  );
}