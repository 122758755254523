export const shortAddress = (str: any) => {
    if (!str) {
        return ""
    }
    const len = str.length;
    const first = str.substring(0, 8)
    const last = str.substring(len - 4, len)
    return first + "..." + last
}

export const timeLeftMinute = (value) => {
    const result = +new Date(value * 1000) - +new Date();
    if (result > 0) {
        return Math.floor(result / (1000 * 60));
    }
    return 0;
}

export function getTimeRemaining(endtime) {
    const total = +new Date(endtime) - +new Date();
    if (total <= 0) {
        return null;
    }
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        days,
        hours,
        minutes,
        seconds
    };
}