import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import SuspenseLoader from '../components/SuspenseWithChunkError/index';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const PageNotFoundView = Loader(lazy(() => import('../components/404')));

const Home = Loader(lazy(() => import('../pages/Home')));
const Constructor = Loader(lazy(() => import('../pages/Constructor')));
const Breeding = Loader(lazy(() => import('../pages/Breeding')));
const MySuperPass = Loader(lazy(() => import('../pages/myNfts')));
const OpenPass = Loader(lazy(() => import('../pages/openPass')));
const FixCommit = Loader(lazy(() => import('../pages/FixCommit')));


export const mainRoutes: PartialRouteObject[] = [
  {
    path: '*',
    element: <Navigate to='404' />
  },
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/constructor',
    element: <Constructor />,
  },
  {
    path: '/breeding',
    element: <Breeding />,
  },
  {
    path: '/open-pass/:tokenAddress/:tokenId',
    element: <OpenPass />,
  },
  {
    path: '/fix-commit/:tokenAddress/:tokenId',
    element: <FixCommit />,
  },
  {
    path: '/my-superpass',
    element: <MySuperPass />,
  },
  {
    path: '404',
    element: <PageNotFoundView />
  },

]
