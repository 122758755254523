import { Box } from '@mui/material';
import { Link } from "react-router-dom";

const logo_url = '/logo_singsing.svg';
const beta = '/assets/icons/beta.svg';

export function LogoFooter(): JSX.Element {
    return (
        <Link to="/">
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <img src={logo_url} style={{ height: "50px" }} alt="..." />
                <Box
                    sx={{
                        height: 50,
                        pt: .5,
                        paddingLeft: .5,
                    }}
                >
                    <img src={beta} alt='' />
                </Box>
            </Box>
        </Link>
    )
}