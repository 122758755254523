// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function AccountIcon({ sx }: BoxProps) {

  return (
    <Box sx={{ width: 17, height: 18, ...sx }}>
        <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3327 4.70833C12.3327 5.725 11.9288 6.70002 11.2099 7.41891C10.491 8.1378 9.51601 8.54167 8.49935 8.54167C7.48269 8.54167 6.50766 8.1378 5.78877 7.41891C5.06988 6.70002 4.66602 5.725 4.66602 4.70833C4.66602 3.69167 5.06988 2.71665 5.78877 1.99776C6.50766 1.27887 7.48269 0.875 8.49935 0.875C9.51601 0.875 10.491 1.27887 11.2099 1.99776C11.9288 2.71665 12.3327 3.69167 12.3327 4.70833V4.70833Z" stroke="#68F9FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.49967 9.5C6.46635 9.5 4.5163 10.227 3.07852 11.521C1.64074 12.815 0.833008 14.57 0.833008 16.4H16.1663C16.1663 14.57 15.3586 12.815 13.9208 11.521C12.483 10.227 10.533 9.5 8.49967 9.5V9.5Z" stroke="#68F9FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Box>
  );

}