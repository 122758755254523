import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { selectSlice } from 'pages/Breeding/components/selectSlice';

export const store = configureStore({
  reducer: {
    select: selectSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
