/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, ClickAwayListener, List, ListItem, Paper, Popper } from "@mui/material";
import { ReactComponent as ArrowSvg } from "./arrowDown.svg";
import { ReactComponent as FlagENSvg } from "./flag-en.svg";
import { ReactComponent as FlagVNSvg } from "./flag-vn.svg";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, useMediaQuery } from "@mui/material";

type LanguageProps = {
    key: string,
    label: string,
    dir: string,
    active: boolean,
    icon: any,
}
type LanguageMap = {
    en: LanguageProps,
    vn: LanguageProps,
}

const languageMap: LanguageMap = {
    en: { key: "English", label: "English", dir: "ltr", active: true, icon: <FlagENSvg /> },
    vn: { key: "Tiếng Việt", label: "Tiếng Việt", dir: "ltr", active: false, icon: <FlagVNSvg /> }
};

const LanguageSelect = () => {

    const selected = localStorage.getItem("i18nextLng") || "vn";
    const { t } = useTranslation();

    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    React.useEffect(() => {
        document.body.dir = languageMap[selected].dir;
    }, [selected]);

    const closeMenu = () => {
        setMenuOpen(false)
    }

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    return (
        <div>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <Button ref={anchorRef} onClick={handleToggle} 
                    sx={{
                        border: '1.5px solid #00e0f7',
                        borderRadius: '10px',
                        background: 'rgba(0,173,249,.1)',
                        minWidth: 139,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                                fontSize: 14,
                            }}
                        >
                            {languageMap[selected].icon}
                            &nbsp;
                            {languageMap[selected].key}
                        </Box>
                        <ArrowSvg style={{ color: "#ffffff", width: "14px" }} />
                    </Box>

                </Button>


                <Popper
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    //onClose={closeMenu}
                    role={undefined} transition disablePortal
                    placement='bottom-start'
                    style={{
                        position: 'absolute',
                        top: 'auto',
                        left: 'auto',
                    }}
                >
                    <Paper 
                        sx={{
                            background: '#351a59',
                            borderRadius: '10px',
                            color: '#ffff',
                            mt: 1,
                            px: 1.4,
                            minWidth: 150,
                        }}
                    >
                        <ClickAwayListener onClickAway={closeMenu}>
                            <List>

                                {Object.keys(languageMap)?.map(item => (
                                    <ListItem
                                        button
                                        key={item}
                                        onClick={() => {
                                            i18next.changeLanguage(item);
                                            localStorage.setItem("i18nextLng", item)
                                            setMenuOpen(false)
                                        }}
                                    >
                                        {languageMap[item].label}
                                    </ListItem>
                                ))}

                            </List>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Box>
        </div>

    );
}

export default LanguageSelect
